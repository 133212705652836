<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getOfferErrors.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getOfferErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('offers:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('offers:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6"
                                        ><v-text-field
                                            v-model="getOfferModal.title"
                                            :error-messages="
                                                getOfferErrors.stepOne.fields
                                                    .title
                                            "
                                            :label="`${$t('offers:title')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    class="float-right"
                                                    style="width: 65%;"
                                                    v-model="
                                                        getOfferModal.expirationDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getOfferModal.expirationDate = null
                                                    "
                                                    :label="`${$t(
                                                        'offers:expirationDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getOfferModal.expirationDate
                                                "
                                                @input="startDate = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-combobox
                                            v-model="getOfferModal.contractor"
                                            :label="`${$t(
                                                'offers:contractor'
                                            )}*`"
                                            hint="Zacznij pisać..."
                                            @keyup="
                                                setContacts($event.target.value)
                                            "
                                            @input="assignID()"
                                            :hide-no-data="hideContacts"
                                            :items="getContactsInput.items"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getOfferErrors.stepOne.fields
                                                    .contractor
                                            "
                                            @change="ifClientOperationLocked"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-combobox
                                            :label="`${$t(
                                                'offers:representative'
                                            )}`"
                                            v-model="
                                                getOfferModal.representative
                                            "
                                            @keyup="
                                                setRepresentatives(
                                                    $event.target.value
                                                )
                                            "
                                            :item-text="text"
                                            return-object
                                            append-outer-icon="mdi-account-plus"
                                            item-value="_id"
                                            :items="
                                                getRepresentativeInput.items
                                            "
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template slot="append-outer">
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :close-on-click="false"
                                                    @input="
                                                        v =>
                                                            v ||
                                                            clearMiniModal()
                                                    "
                                                    max-width="300"
                                                    min-width="300"
                                                    origin="center"
                                                    v-model="showMenu"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on: menu,
                                                        }"
                                                    >
                                                        <v-tooltip top>
                                                            <template
                                                                v-slot:activator="{
                                                                    on: tooltip,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    small
                                                                    icon
                                                                    :disabled="
                                                                        getContactsInput
                                                                            .items
                                                                            .length >
                                                                        0
                                                                            ? false
                                                                            : true
                                                                    "
                                                                >
                                                                    <v-icon
                                                                        v-on="{
                                                                            ...menu,
                                                                            ...tooltip,
                                                                        }"
                                                                        @click="
                                                                            showMenu = true
                                                                        "
                                                                        >mdi-plus</v-icon
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'offers:addRepresentative'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <h3>
                                                                        {{
                                                                            `${$t(
                                                                                'offers:additionRepresentative'
                                                                            )}`
                                                                        }}
                                                                    </h3>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.name
                                                                        "
                                                                        :label="`${$t(
                                                                            'offers:representativeName'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.lastname
                                                                        "
                                                                        :label="`${$t(
                                                                            'offers:representativeLastname'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.email
                                                                        "
                                                                        :label="`${$t(
                                                                            'offers:representativeEmail'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.phoneNumber
                                                                        "
                                                                        :label="`${$t(
                                                                            'offers:representativePhoneNumber'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0 d-flex justify-end"
                                                                >
                                                                    <v-btn
                                                                        class="buttons buttons--cancel mr-2"
                                                                        @click="
                                                                            clearMiniModal()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'cancel'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                    <v-btn
                                                                        class="buttons buttons--add"
                                                                        :disabled="
                                                                            disabled
                                                                        "
                                                                        @click="
                                                                            additionRepresentative()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'add'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="getOfferModal.offerType"
                                            :items="
                                                getRegistryDictionaries.offerType
                                                    ? getRegistryDictionaries.offerType
                                                    : []
                                            "
                                            dense
                                            :label="$t('offers:offerKind')"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="getOfferModal.customerType"
                                            :items="
                                                getRegistryDictionaries.customerType
                                                    ? getRegistryDictionaries.customerType
                                                    : []
                                            "
                                            dense
                                            :label="$t('offers:customerKind')"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="`${$t('offers:notes')}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="getOfferModal.notes"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
            'checkIsClientOperationLocked',
        ]),
        ...mapMutations(['setContactsInput']),
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        assignID() {
            if (
                this.getOfferModal.contractor &&
                this.getOfferModal.contractor._id
            ) {
                this.getOfferModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getOfferModal.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getOfferModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
        async ifClientOperationLocked() {
            const clientId = this.$get(this.getOfferModal, 'contractor', '')
                ?._id

            if (clientId) {
                const payload = {
                    id: clientId,
                }
                const checkClientOperationLocked = await this.checkIsClientOperationLocked(
                    payload
                )

                if (checkClientOperationLocked.data) {
                    console.log(checkClientOperationLocked.data)
                    this.getOfferErrors.stepOne.fields.contractor =
                        'Klient na liście zablokowanych'

                    this.$forceUpdate()
                } else {
                    this.getOfferErrors.stepOne.fields.contractor = ''

                    this.$forceUpdate()
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            'getOfferModal',
            'getOfferErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
        ]),
    },
}
</script>
