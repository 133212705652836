<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getOffersRegistry.name"
                    :avatarData="getOffersRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    ref="offerTable"
                    :title="getOffersRegistry.name"
                    :items="getOffersTable.items"
                    :headers="headers"
                    :length="getOffersTable.pages"
                    :page="getOffersTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getOffersSearchFields.searchFields"
                    :loading="getOffersTable.loading"
                >
                    <Columns slot="columns" :items="getOffersTable.items" />
                </Table>
                <Modal
                    :title="$t('offers:addingOffer')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addOffer()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('offers:addOffer') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Offers/Table/Columns'
import AEContent from './../../../components/Registries/Offers/Modal/Content'
import Buttons from './../../../components/Registries/Offers/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('offers:index'),
                    value: 'index',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:contractor'),
                    value: 'contractor.name',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:title'),
                    value: 'title',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:creator'),
                    value: 'createdBy.name join createdBy.lastname',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:creationDate'),
                    value: 'createdAt',
                    width: '9%',
                },
                {
                    text: '',
                    value: 'form',
                    width: '5%',
                    sortable: false,
                },
                {
                    text: this.$t('offers:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getOffersRegistry',
            'getOffersTable',
            'getOffersSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchOffersTable', 'fetchUsers']),
        ...mapMutations([
            'clearOffersModal',
            'clearOfferErrors',
            'setOffersTable',
            'clearOffersTable',
            'setOffersSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            // this.clearCarsErrors()
            this.open = false
        },
        async addOffer() {
            this.open = true
            this.clearOfferErrors()
            this.clearOffersModal()
        },
        setSearchFields(searchFields) {
            this.setOffersSearch(searchFields)
        },
        setSearch(search) {
            this.setOffersTable({ search, page: 1 })
            this.fetchOffersTable()
        },
        setPage(page) {
            this.setOffersTable({ page })
            this.fetchOffersTable()
        },
        setSort(sort) {
            this.setOffersTable({ sort })
            this.fetchOffersTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearOffersTable()
            this.fetchOffersTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/offers/:id')
            store.commit('clearOffersTable')
        store.dispatch('fetchOffersTable').then(() => next())
    },
}
</script>
