<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === INDEX === === -->
            <td class="truncate">{{ item.index }}</td>
            <!-- === === CONTRACTOR AND REPRESENTANT === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$get(item.contractor, 'name', '')"
                    :secondLine="findRepresentative(item)"
                />
            </td>
            <!-- === === TITLE === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$get(item, 'title', '')"
                    :secondLine="`${$get(item, 'offerType.field', '')}`"
                />
            </td>

            <!-- === === CREATOR === === -->
            <td class="truncate">
                {{
                    $get(item, 'createdBy.name') +
                    ' ' +
                    $get(item, 'createdBy.lastname')
                }}
            </td>
            <!-- === === CREATED TIME === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="
                        item.createdAt
                            ? $moment(item.createdAt).format(`DD-MM-YYYY`)
                            : $t('global:empty')
                    "
                    :secondLine="
                        item.createdAt
                            ? $moment(item.createdAt).format(`HH:mm`)
                            : $t('global:empty')
                    "
                />
            </td>
            <!-- === === WEBICON === === -->
            <td class="truncate">
                <v-icon v-if="item.webForm" color="secondary" small
                    >mdi-web</v-icon
                >
            </td>
            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                    >{{ $get(item, 'status.name', $t('users:empty')) }}</v-chip
                >
            </td>
            <td class="d-flex align-center justify-end">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item._id)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn small router :to="`/offers/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import ConfirmContent from '../ConfirmModal/ConfirmContent.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    props: ['items'],
    components: {
        ConfirmContent,
    },
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        findRepresentative(item) {
            const id = item.representative
            if (id) {
                const reprezentative = item.contractor?.representatives?.find(
                    e => e._id === id
                )
                return `${this.$get(
                    reprezentative,
                    'name',
                    'Brak'
                )} ${this.$get(reprezentative, 'lastname', '')} ${
                    reprezentative && reprezentative.email ? '•' : ''
                } ${this.$get(reprezentative, `email`, '')} ${
                    reprezentative && reprezentative.phoneNumber ? '•' : ''
                } ${this.$get(reprezentative, 'phoneNumber', '')}`
            }
            return ''
        },
        ...mapActions(['deleteOffers']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteOffers({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
    },
}
</script>
